import { useContext } from 'react';
import AuthContext from '../context/AuthContext';


const BASE_URL = import.meta.env.VITE_BASE_API_URL;

const headers = {
    'Content-Type': 'application/json',
}

const useApi = () => {
    const { authTokens, handleUserLogout } = useContext(AuthContext);

    const clientGet = async (url) => {
        const response = await fetch(`${BASE_URL}${url}`, {
            headers: {
                ...headers,
                Authorization: `Bearer ${authTokens?.jwt}`,
            },
        });

        return await handleResponse(response);
    }
    const clientGetFile = async (url) => {
        const response = await fetch(`${BASE_URL}${url}`, {
            headers: {
                ...headers,
                Authorization: `Bearer ${authTokens?.jwt}`,
            },
        });

        return response
    }

    const clientPost = async (url, data) => {
        const isFormData = data instanceof FormData;
        const response = await fetch(`${BASE_URL}${url}`, {
            method: 'POST',
            headers: {
                ...(isFormData ? {} : headers),
                Authorization: `Bearer ${authTokens?.jwt}`,
            },
            body: isFormData ? data : JSON.stringify(data),
        });

        return await handleResponse(response);
    }

    const clientPatch = async (url, data) => {
        const response = await fetch(`${BASE_URL}${url}`, {
            method: 'PATCH',
            headers: {
                ...headers,
                Authorization: `Bearer ${authTokens?.jwt}`,
            },
            body: JSON.stringify(data),
        });

        return await handleResponse(response);
    }

    const clientDelete = async (url) => {
        const response = await fetch(`${BASE_URL}${url}`, {
            method: 'DELETE',
            headers: {
                ...headers,
                Authorization: `Bearer ${authTokens?.jwt}`,
            },
        });

        return await handleResponse(response);
    }

    const handleResponse = async (response) => {
        if (!response.ok) {
            // Check if the response is a 401 Unauthorized to logout the user
            if (response.status === 401 || response.status === 403) {
                handleUserLogout();
            }
            const errorData = await response.json();
            const error = new Error(errorData.message);
            error.response = response;
            throw error;
        }

        return response.json();
    }

    return {
        clientGet,
        clientGetFile,
        clientPost,
        clientPatch,
        clientDelete,
    }
}


const useAuthApi = () => {
    
    const clientPost = async (url, data) => {
        const response = await fetch(`${BASE_URL}${url}`, {
            method: 'POST',
            headers: {
                ...headers,
            },
            body: JSON.stringify(data),
        });

        return await handleResponse(response);
    }


    const handleResponse = async (response) => {
        if (!response.ok) {
            const errorData = await response.json();
            const error = new Error(errorData.message);
            error.response = response;
            throw error;
        }
        return response.json();
    }

    return {
        clientPost,
    }
}

export { useApi, useAuthApi, BASE_URL, headers };