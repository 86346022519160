import { createContext, useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'
import { useAuthApi, BASE_URL, headers } from '../api/client';

const AuthContext = createContext()

export default AuthContext;


export const AuthProvider = ({children}) => {
    const storedTokens = localStorage.getItem('authTokens')
    const parsedTokens = storedTokens ? JSON.parse(storedTokens) : null

    const [user, setUser] = useState(parsedTokens ? jwtDecode(parsedTokens.jwt) : null)
    const [authTokens, setAuthTokens] = useState(parsedTokens)
    const [canWrite, setCanWrite] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const { clientPost } = useAuthApi()

    const handleUserLogin = async (username, password) => {
        const body = { username, password }
        const result = await clientPost('/users/auth/login', body)
        const { jwt, refreshToken, userData } = result
        const tokens = { jwt, refreshToken }
        setAuthTokens(tokens)
        setUser(userData)
        localStorage.setItem('authTokens', JSON.stringify(tokens))
        navigate('/ventas/general')
    }

    const handleUserLogout = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        navigate('/login')
    }

    const updateTokens = async () => {
        try {
            const body = { refreshToken: authTokens.refreshToken }
            const result = await clientPost('/users/auth/refresh', body)
            const { jwt } = result
            const updatedTokens = { ...authTokens, jwt }
            setAuthTokens(updatedTokens)
            localStorage.setItem('authTokens', JSON.stringify(updatedTokens))
        }
        catch (error) {
            console.error(error)
            handleUserLogout()
        }
        finally {
            setLoading(false)
        }
    }

    const switchLocalToken = async (newLocal) => {
        try {
            const body = { apiKey: newLocal }
            const result = await fetch(`${BASE_URL}/users/auth/switch-local`, {
                method: 'POST',
                headers: {
                    ...headers,
                    Authorization: `Bearer ${authTokens.jwt}`
                },
                body: JSON.stringify(body)
            })
            if (!result.ok) {
                throw new Error('Error switching local token')
            }
            const data = await result.json()
            const { jwt, refreshToken, userData } = data
            const tokens = { jwt, refreshToken }
            setAuthTokens(tokens)
            setUser(userData)
            localStorage.setItem('authTokens', JSON.stringify(tokens))
        }
        catch (error) {
            console.error(error)
            handleUserLogout()
        }
        finally {
            setLoading(false)
        }
        
    }

    useEffect(() => {
        if (authTokens) {
            if (['Administrador', 'Editor'].includes(user.role)) {
                setCanWrite(true)
                if (user.role === 'Administrador') {
                    setCanEdit(true)
                }
            }
        }
    })

    const contextData = {
        user,
        authTokens,
        canWrite,
        canEdit,
        switchLocalToken,
        handleUserLogin,
        handleUserLogout
    }


    useEffect(() => {
        if (loading) {
            if (authTokens) {
                updateTokens()
            }
            setLoading(false)
        }


        let fourMinutes = 1000 * 60 * 4
        // let fifteenSeconds = 1000 * 15
        let interval = setInterval(() => {
            if (authTokens) {
                updateTokens()
            }
        }, fourMinutes)
        return () => clearInterval(interval)
    }, [authTokens, loading])

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null: children}
        </AuthContext.Provider>
    )
}

